import useStartNewBrainSearch from "@/admin/integrations/chat-bot/util/useStartNewBrainSearch"
import BrainSearchInput from "@/brain-search/internal/BrainSearchInput"
import BrainSearchSuggestionList from "@/brain-search/internal/BrainSearchSuggestionList"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import BannerImage from "@components/banner-image/BannerImage"
import { DiscoText } from "@disco-ui"
import { getContrastRatio, useTheme } from "@material-ui/core"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import classNames from "classnames"
import ReactDOM from "react-dom"
import { ConnectionHandler, graphql, useFragment } from "react-relay"
import DashboardBlockAdminDropdown from "../DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "./DashboardBlockItemTemplate"
import {
  DashboardBlockHeroHeaderText,
  DashboardBlockHeroMode,
  HeroDashboardBlockFragment$key,
} from "./__generated__/HeroDashboardBlockFragment.graphql"
import { HeroDashboardBlockMutation } from "./__generated__/HeroDashboardBlockMutation.graphql"

const HEADER: Record<DashboardBlockHeroHeaderText, string> = {
  default_hello: "Hello {Name}",
  hello_prefix_wave: "👋 Hello {Name}",
  hello_suffix_wave: "Hello {Name} 👋",
  default_welcome: "Welcome {Name}",
  welcome_prefix_wave: "👋 Welcome {Name}",
  welcome_suffix_wave: "Welcome {Name} 👋",
  default_hi: "Hi {Name}",
  hi_prefix_wave: "👋 Hi {Name}",
  hi_suffix_wave: "Hi {Name} 👋",
  hello_prefix_sparkles: "✨ Hello {Name}",
  "%future added value": "",
}

interface Props {
  dashboardBlockKey: HeroDashboardBlockFragment$key
  index?: number
}

export default function HeroDashboardBlock({ dashboardBlockKey, index }: Props) {
  const showOnHoverClasses = useShowOnHoverStyles()
  const theme = useTheme()
  const { authUser } = useAuthUser({ required: true })
  const TESTID = "HeroDashboardBlock"
  const activeOrganization = useActiveOrganization()
  const { brainSearch } = useFeatureFlags()
  const startNewBrainSearch = useStartNewBrainSearch()

  const block = useFragment<HeroDashboardBlockFragment$key>(
    graphql`
      fragment HeroDashboardBlockFragment on HeroDashboardBlock {
        id
        showWelcomeText
        askAi
        header
        subText
        color
        heroMode: mode
        cover
        textColor
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const form = useFormStore<HeroDashboardBlockMutation>(
    graphql`
      mutation HeroDashboardBlockMutation(
        $input: CreateBrainSearchInput!
        $connections: [ID!]!
      ) {
        response: createBrainSearch(input: $input) {
          node
            @prependNode(connections: $connections, edgeTypeName: "BrainSearchNodeEdge") {
            ...BrainSearchSidebarList_ItemFragment @relay(mask: false)
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { title: "" }
  )

  const askAIEnabled = brainSearch && block.askAi
  const classes = useStyles({
    askAIEnabled,
    textColor: block.textColor || undefined,
    heroMode: block.heroMode,
  })
  const isDark =
    block.heroMode === "color" && block.color
      ? getContrastRatio(theme.palette.common.white, block.color) > 1.5
      : false
  const name = block.header
    ? HEADER[block.header].replace("{Name}", authUser.firstName || "")
    : ""

  if (!block) return null

  const el = document.querySelector("#community-cover-banner-block-anchor")
  if (!el) return null
  return ReactDOM.createPortal(
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <div className={classNames(classes.container, showOnHoverClasses.hoverable)}>
        {renderBanner()}
        <DashboardBlockAdminDropdown
          dashboardBlockKey={block}
          className={classNames(classes.dropdown, showOnHoverClasses.showable)}
        />

        {block.showWelcomeText && block.header && (
          <div className={classes.header}>
            <DiscoText
              testid={`${TESTID}.header`}
              variant={"heading-lg"}
              color={isDark ? "common.white" : "common.black"}
              classes={{
                h3: classes.headerText,
              }}
            >
              {name}
            </DiscoText>
            <DiscoText
              testid={`${TESTID}.sub-text`}
              color={isDark ? "common.white" : "common.black"}
              classes={{
                root: classes.headerText,
              }}
            >
              {block.subText}
            </DiscoText>
          </div>
        )}
      </div>

      {askAIEnabled && (
        <div className={classes.inputRoot}>
          <BrainSearchInput
            onSubmit={redirectToAskAI}
            onChange={handleChangeQuery}
            classes={{
              root: classes.input,
            }}
          />

          <BrainSearchSuggestionList form={form} onSelect={redirectToAskAI} />
        </div>
      )}
    </DashboardBlockItemTemplate>,
    el
  )

  function renderBanner() {
    switch (block.heroMode) {
      case "no_fill":
        return (
          <div
            className={classNames(classes.cover, classes.colorFill)}
            style={{
              backgroundColor: theme.palette.background.default,
            }}
          />
        )
      case "color":
        return (
          <div
            className={classNames(classes.cover, classes.colorFill)}
            style={{
              backgroundColor: block.color!,
            }}
          />
        )
      case "image":
        return <BannerImage coverPhoto={block.cover!} className={classes.cover} />
      default:
        return null
    }
  }

  function handleChangeQuery(text: string) {
    form.state.title = text
  }

  async function redirectToAskAI() {
    const { response, didSave } = await form.submit(form.state, {
      connections: [
        ConnectionHandler.getConnectionID(
          activeOrganization!.viewerMembership!.id,
          "BrainSearchSidebarList__brainSearches"
        ),
      ],
    })

    if (!didSave || !response?.node?.id) return

    startNewBrainSearch(response.node.id, form.state.title)
  }
}

interface StyleProps {
  textColor?: string
  askAIEnabled: boolean
  heroMode: DashboardBlockHeroMode
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(0),
  },
  cover: {
    borderRadius: 0,
  },
  colorFill: ({ askAIEnabled, heroMode }: StyleProps) => ({
    height: "260px",
    ...styleIf(!askAIEnabled && heroMode === "no_fill", {
      height: "150px",
    }),
    [theme.breakpoints.down("sm")]: {
      height: "90px",
      ...styleIf(askAIEnabled, {
        height: "150px",
      }),
    },
  }),
  dropdown: {
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
    zIndex: 2,
  },
  header: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  headerText: ({ textColor }: StyleProps) => ({
    ...styleIf(textColor?.length, {
      color: textColor,
    }),
  }),
  inputRoot: {
    width: "100%",
    maxWidth: "689px",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: theme.spacing(-3),
    gap: theme.spacing(4),
    padding: theme.spacing(0, 2),
  },
  input: {
    maxWidth: "485px",
    alignSelf: "center",

    [theme.breakpoints.up("xl")]: {
      width: "40%",
    },
  },
}))
