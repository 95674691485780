import { useActiveProduct } from "@/core/context/ActiveProductContext"
import {
  DashboardBlockListQuery,
  DashboardBlockPosition,
} from "@/dashboard/__generated__/DashboardBlockListQuery.graphql"
import AddDashboardBlockButton from "@/dashboard/add/AddDashboardBlockButton"
import DashboardBlockItem from "@/dashboard/blocks/DashboardBlockItem"
import { DashboardProvider } from "@/dashboard/util/DashboardContext"
import { useCanCustomizeDashboard } from "@/dashboard/util/useCanCustomizeDashboard"
import PageContent from "@/main/page/content/PageContent"
import ProductVisibilityBanner from "@/product/util/ProductVisibilityBanner"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { Grid, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { range } from "@utils/array/arrayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { Droppable } from "react-beautiful-dnd"
import { graphql, useLazyLoadQuery } from "react-relay"

interface Props {
  dashboardId: GlobalID
  position: DashboardBlockPosition
}

function DashboardBlockList(props: Props) {
  const { dashboardId, position } = props
  const activeProduct = useActiveProduct()
  const { productAdminBlock } = useFeatureFlags()

  const { node } = useLazyLoadQuery<DashboardBlockListQuery>(
    graphql`
      query DashboardBlockListQuery(
        $dashboardId: ID!
        $position: DashboardBlockPosition!
      ) {
        node(id: $dashboardId) {
          __typename
          ... on Dashboard {
            id
            layout
            landingPageId
            forYou
            appId
            forYou
            blocks(position: $position) {
              edges {
                node {
                  id
                  kind
                  ...DashboardBlockItemFragment
                }
              }
            }
            ...useCanEditDashboardFragment
          }
        }
      }
    `,
    { dashboardId, position }
  )

  const dashboard = Relay.narrowNodeType(node, "Dashboard")
  const canAddBlocks = useCanCustomizeDashboard(dashboard || null)
  const classes = useStyles({
    canAddBlocks,
  })

  if (!dashboard) return null

  // Prepare blocks to display
  const blocks = Relay.connectionToArray(dashboard.blocks)
  const stickyBlockItem = blocks.find(
    (b) => b.kind === "banner" || b.kind === "community_welcome_hero" || b.kind === "hero"
  )

  // pathways are administered by org admins or owners, so show them the admin block
  // otherwise only managers & instructors should see the admin block for courses
  const productAdminBlockItem = blocks.find((b) => b.kind === "product_admin")
  const showProductAdminBlock =
    (activeProduct?.type === "pathway" || activeProduct?.viewerIsManagerOrInstructor) &&
    productAdminBlock &&
    productAdminBlockItem
  const filteredBlocks = blocks.filter(
    (b) =>
      b.kind !== "banner" &&
      b.kind !== "community_welcome_hero" &&
      b.kind !== "product_admin" &&
      b.kind !== "hero"
  )

  // Manage ordering index of dashboard blocks
  let startIndex = 0
  if (stickyBlockItem) startIndex += 1
  if (showProductAdminBlock) startIndex += 1

  return (
    <DashboardProvider dashboard={dashboard}>
      <Droppable droppableId={`drag-drop__droppable-${position}-blocks`}>
        {(provided) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={classes.container}
            data-testid={`DashboardBlockList.${position}`}
          >
            {stickyBlockItem && (
              <>
                <DashboardBlockItem
                  key={stickyBlockItem.id}
                  dashboardBlockKey={stickyBlockItem}
                  index={0}
                />

                {stickyBlockItem.kind === "banner" && !productAdminBlock && (
                  <ProductVisibilityBanner marginTop={2} />
                )}
              </>
            )}

            {showProductAdminBlock && (
              <div style={{ marginTop: "16px" }}>
                <DashboardBlockItem
                  key={productAdminBlockItem.id}
                  dashboardBlockKey={productAdminBlockItem}
                  index={1}
                />
              </div>
            )}

            {canAddBlocks &&
              (activeProduct ||
                dashboard.landingPageId ||
                !filteredBlocks.length ||
                dashboard.forYou) && (
                <AddDashboardBlockButton
                  dashboardId={dashboardId}
                  position={position}
                  ordering={startIndex}
                >
                  {"Add Block"}
                </AddDashboardBlockButton>
              )}
            {filteredBlocks.map((block, i) => (
              <DashboardBlockItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${block.id}-${i}`} // Add array index to force remount on reorder, fixing error with sidebar carousel blocks
                dashboardBlockKey={block}
                index={startIndex + i}
              />
            ))}
          </ul>
        )}
      </Droppable>
    </DashboardProvider>
  )
}

interface StyleProps {
  canAddBlocks: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: (props: StyleProps) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: props.canAddBlocks ? undefined : theme.spacing(2.5),
    minHeight: "100%",
  }),
}))

export function DashboardBlockListSkeleton() {
  const theme = useTheme()

  return (
    <PageContent>
      <div style={{ position: "relative", flexGrow: 1 }}>
        {/* Just some random blocks to take up space... */}
        <Grid container spacing={2}>
          {range(3).map((i) => (
            <Grid key={i} item xs={12}>
              <Skeleton
                variant={"rect"}
                style={{
                  borderRadius: theme.measure.borderRadius.big,
                  height: "300px",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </PageContent>
  )
}

export default Relay.withSkeleton({
  component: DashboardBlockList,
  skeleton: DashboardBlockListSkeleton,
})
